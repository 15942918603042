<template>
  <div v-if="!loading">
    <!-- BREADCRUMBS -->
    <custom-breadcrumb
    :pageTitle="$t('forms.edit_model', {model: $t('models.group')})"
    :breadcrumb="breadcrumb"
    ></custom-breadcrumb>

    <b-row>
        <b-col>
          <div class="mb-1" v-if="!group.owner">
            <b-alert
              variant="warning"
              show
            >
              <div class="alert-body">
                <span><strong>{{$t('Warning')}}.</strong> {{$t('You are not the owner of this group. You can only modify some fields.')}}</span>
              </div>
            </b-alert>
          </div>
          <!-- v-if="group.id !=0 " -->
          <b-card >
            <b-tabs v-model="tabIndex" v-if="group">
              <b-tab>
                <template #title>
                  <feather-icon
                    icon="InfoIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">{{$t('models.group')}}</span>
                </template>
                <tab-main
                  :group="group"
                  @refetch-data="getData(group.id)"
                  class="mt-2 pt-75"
                />
              </b-tab>

              <!-- Users -->
              <b-tab>
                <template #title>
                  <feather-icon
                    icon="Share2Icon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">{{$t('general.share')}}</span>
                </template>
                <tab-users
                  :group="group"
                  :tutors="tutors"
                  @refetch-data="getData(group.id)"
                  class="mt-2 pt-75"
                />
              </b-tab>

              <!-- Dreamers -->
              <b-tab>
                <template #title>
                  <feather-icon
                    icon="SmileIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">{{$t('Dreamers')}}</span>
                </template>
                <tab-dreamers
                  :group="group"
                  :dreamers="dreamers"
                  :all_dreamers="all_dreamers"
                  @refetch-data="getData(group.id)"
                  class="mt-2 pt-75"
                />
              </b-tab>

              <!-- CUSTOMIZATION -->
              <b-tab>
                <template #title>
                  <feather-icon
                    icon="SlidersIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">{{$t('customize.customization')}}</span>
                </template>
                <customization-component
                  :dreamers="[group.id]"
                  :isGroup="true"
                  @refetch-data="getData(group.id)"
                  class="mt-2 pt-75"
                />
              </b-tab>

              <!-- CONTENT RESTRICTION -->
              <b-tab>
                <template #title>
                  <feather-icon
                    icon="ClockIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">{{$t('customize.mandatory')}}</span>
                </template>
                <content-restriction-component
                  :info="[group]"
                  :dreamers="[group.id]"
                  :isGroup="true"
                  @refetch-data="getData(group.id)"
                  class="mt-2 pt-75"
                />
              </b-tab>

              <b-tab>
                <template #title>
                  <feather-icon
                    icon="UnlockIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">{{$t('children_passwords.edit_children_passwords')}}</span>
                </template>
                  <children-passwords-components 
                    :isGroup="true"
                    :info="[group]"
                    :dreamers="[group.id]"
                    @refetch-data="getData(group.id)"
                    class="mt-2 pt-75"
                  />
              </b-tab>

            </b-tabs>
          </b-card>
        
          <!-- ALERT -->
          <!-- <b-alert
            v-else
            variant="warning"
            show
          >
            <h4 class="alert-heading">
              {{$t('Not Authorized')}}
            </h4>
            <div class="alert-body">
              <p>{{$t('dreamer.not_owner', {model: $t('models.group')})}}</p>
            </div>
          </b-alert> -->

        </b-col>
      </b-row>
  </div>
</template>

<script>
import { getGroup  } from '@/api/routes'
import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'
import TabMain from './edit/TabMain.vue'
import TabUsers from './edit/TabUsers.vue'
import TabDreamers from './edit/TabDreamers.vue'

import CustomizationComponent from '@/views/sl-components/CustomizationComponent.vue'
import ContentRestrictionComponent from '@/views/sl-components/ContentRestrictionComponent.vue'
import ChildrenPasswordsComponents from "@/views/sl-components/ChildrenPasswordsComponents.vue";


import {
  BTab, BTabs, BCard, BAlert, BLink,
  BRow, BCol
} from 'bootstrap-vue'
export default {
  components:{
    CustomBreadcrumb,
    TabMain,
    TabUsers,
    TabDreamers,

    CustomizationComponent,
    ContentRestrictionComponent,
    ChildrenPasswordsComponents,
    
    BTab, BTabs, BCard, BAlert, BLink, BRow, BCol
  },
  data(){
    return {
      loading: true,
      group: {},
      tutors:[],
      dreamers:[],
      all_dreamers:[],
      tabIndex:null,

    }
  },
  mounted(){
    this.getData();
    this.tabIndex = this.$router.currentRoute.params.tab || 0;
  },
  computed:{
    breadcrumb(){
      return [
        {
          text: this.$t('Groups'),
          to: 'groups'
        },
        {
          text: this.group.id==0 ? this.$t('table.no_group') : this.group.name,
          to: 'group-view',
          params:{
            id: this.group.id
          }
        },
        {
          text: this.$t('forms.edit'),
          active: true,
        },
      ]
    },
  },
  methods:{
    async getData(){
        // console.log("loading")

        await this.$http.get(getGroup+'/'+this.$route.params.id).then( response =>{
          // console.log(response)
          if(response.status ==200){
            this.group = response.data.group;
            this.dreamers = response.data.dreamers;
            this.all_dreamers = response.data.all_dreamers.filter(d => !d.deleted_at);
            this.tutors = response.data.tutors; //Linked, no owners
  
            this.loading = false
          } else {
            this.makeToast('danger', this.$t('Error'), response.data.msg);
          }
        }).catch(error => {
          // console.log("Err -> ", error);
          this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
        })
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  }
}
</script>

<style>

</style>